









































import { Vue, Component } from "vue-property-decorator";
import Users, { User } from "@/store/users";
import * as R from "ramda";

@Component
export default class extends Vue {
  valid: boolean = false;
  model = {
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    title: ""
  };
  originalModel: any = {};

  userStatuses = [
    { text: "Active", value: "ACTIVE" },
    { text: "Inactive", value: "INACTIVE" }
  ];

  error = "";
  isLoading = false;

  fieldRule = [
    (v: any) => !!v || 'This field is required',
  ];
  emailRule = [
    (v: any) => {
      if(v.length > 0) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || 'Invalid e-mail.';
      } else { 
        return !!v || 'This field is required';
      }
    },
  ];
  get isEdit() {
    return !!this.$route.params.id;
  }

  get id() {
    return this.$route.params.id;
  }

  async mounted() {
    this.isLoading = true;
    if (this.isEdit) {
      try {
        const response = await Users.fetchOne({ id: this.id });
        const fetchedUser = response.data as User;
        (this.model as any) = R.clone(fetchedUser);
        this.originalModel = R.clone(fetchedUser);
      } catch (err) {
        this.error = err.message;
      }
    }
    this.isLoading = false;
  }

  submitForm () {
    (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (this.valid) {
      this.save()
    }
  }

  async save() {
    this.isLoading = true;
    try {
      if (this.isEdit) {
        await this.saveEdited();
      } else {
        await this.create();
      }
    } catch (err) {
      this.error = err.message;
    }
    this.isLoading = false;
  }

  async create() {
    const { name, email, password, phoneNumber, title } = this.model;

    await Users.create({
      name,
      email,
      password,
      phoneNumber,
      title
    });

    this.$router.push("/users");
  }

  get changes() {
    const retUndef = (arg: any) => (arg ? arg : undefined);
    const eq = (a1: any, a2: any) => (R.equals(a1, a2) ? false : a1);

    return R.mergeWith(R.compose(retUndef, eq), this.model, this.originalModel);
  }

  async saveEdited() {
    await Users.update({ id: this.id, data: this.changes });

    this.$router.push("/users");
  }

  async remove() {
    const really = confirm("Do you really want to delete this subscriber?");
    if (!really) {
      return;
    }

    this.isLoading = true;
    await Users.remove({ id: this.id });
    this.$router.push('/users');
  }
}
